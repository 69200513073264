/*
    Created on : 8/nov/2018, 13:36:07
    Author     : emers
*/
.background{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top:  0;
  left: 0;
  z-index: 3;
  background: rgba(0,0,0,0.8);
}
h1,p,button{
  font-family: Roboto;
}
.cookie-bar{
  z-index: 4;
  color: #fff;
}
.cookie-bar .cookie-buttons button{
  cursor: pointer;
  color: #fff;
  border: 1px solid #cecece;
  background: transparent;
}
.cookie-bar .cookie-buttons button:hover{
  background: #cecece;
}
.cookie-bar .cookie-buttons #btn-more{
  display: inline-flex;
}
.cookie-buttons a, .cookie-buttons a:hover, .cookie-buttons a:visited,.cookie-buttons a:active,.cookie-buttons a:focus{
  text-decoration: none;
  color: #fff;
  border: none;

}

